import { useAuth } from "@/auth";
import { useLogout } from "@/mutations";
import usersQueries from "@/queries/users";
import { Logout, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

const UserMenu = () => {
  const { setJWT } = useAuth();
  const navigate = useNavigate();
  const { data: user } = useSuspenseQuery(usersQueries.currentUser());
  const userInitials = user?.fullName
    .split(" ")
    .map((n) => n[0])
    .join("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const logoutMutation = useLogout();

  const handleLogout = () => {
    logoutMutation.mutate(undefined, {
      onSuccess: () => {
        setJWT(null);
        enqueueSnackbar(t("eTicketing.Front.Label.loggedOutSnackbar"), {
          variant: "success",
        });

        location.href = "/login";
      },
    });
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar
          sx={{
            backgroundColor: "secondary.main",
          }}
        >
          {userInitials}
        </Avatar>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth="410px"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={8}
          >
            <Avatar
              sx={{
                backgroundColor: "secondary.main",
                width: 80,
                height: 80,
              }}
            >
              {userInitials}
            </Avatar>
            <Typography variant="h6">{user?.fullName}</Typography>
          </Box>
          <Divider flexItem />
          <MenuItem
            onClick={() => {
              void navigate({
                to: '/profile',
              });
              setAnchorEl(null);
            }}
            sx={{
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Person></Person>
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={handleLogout}
            sx={{
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default UserMenu;
